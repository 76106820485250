.fc-view {
    inset:unset !important
}
.fc-multimonth {
    overflow:unset !important;
    border:0px !important;
}

.fc-event-class {
    min-height: 20px !important;
    padding:3px;
}

.fc-event-holiday {
    min-height: 30px !important;
    padding:5px;
}
.fc table {
    font-size:0.8em
}
.fc .fc-multimonth-title {
    padding:0.6em 0px;
    font-weight: 400;
    font-size: 0.8em;
}

.fc .fc-multimonth-multicol .fc-multimonth-month {
    padding: 0px 0.4em 0.8em;
  }

  .fc .fc-col-header-cell-cushion {   
    padding: 4px 4px;
  }  